export const KYC_STEPS = {
  phone: "Phone",
  camera: "Camera",
  bvn: "Bvn",
  address: "Address",
  success: "Success",
};

export const CREDIT_QUALIFICATION_STEPS = {
  bank: "Bank",
  work: "Work",
  success: "success",
};

export const DATA_STATUS = {
  available: "AVAILABLE",
  failed: "FAILED",
  pending: "PENDING",
  flagged: "FLAGGED",
  pendingAdminReview: "PENDING_ADMIN_REVIEW",
};

export const STATEMENT_REQUEST_STATUS = {
  requested: "STATEMENT_REQUESTED",
  sent: "TICKET_SENT",
  confirmed: "STATEMENT_CONFIRMED",
  recieved: "STATEMENT_RECIEVED",
  analyzed: "STATEMENT_ANALYZED",
  failed: "FAILED",
};


