<template>
  <div>
    <div v-if="!showCamera">
      <div v-if="!showPicture">
        <h5
          class="
            md:text-2xl
            text-xl text-center
            font-bold
            primary-text
            leading-10
          "
        >
          Give us camera access
        </h5>
        <p class="text-center text-grey md:text-lg mt-4">
          You need to submit a selfie to proceed. The photo will only be used to
          verify your identity. Don’t forget to smile ;)
        </p>
        <Button
          :loading="loading"
          text="Launch camera"
          class="mt-8"
          width="w-full"
          @click="displayCamera"
          v-if="!showCamera && !showPicture"
        />
      </div>
      <img
        v-else
        :src="picture"
        alt="snapped image"
        class="h-full max-w-full rounded-xl mx-auto"
      />
    </div>
    <!-- <div class="camera-container" v-if="showCamera">
      <v-easy-camera
        v-model="picture"
        @close="closeCamera"
        @approve="chosen"
        :mustApprove="true"
        output="dataUrl"
        fullScreenZIndex="30"
        ref="easyCam"
      ></v-easy-camera>
    </div> -->
    <div class="w-full mt-8">
      <Button
        text="Capture"
        width="w-full hidden md:block"
        v-if="showCamera"
        @click="snap"
      />
      <Button
        v-if="showPicture"
        text="Submit details"
        width="w-full"
        class="mx-auto"
        @click="uploadProfile"
        :loading="loading"
      />
      <p class="text-black mt-4 text-center" v-if="showPicture">
        Don't like this shot?
        <span class="cursor-pointer underline" @click="handleResnap">
          Let's try again</span
        >
      </p>
    </div>

    <!-- <p
      class="
        text-font-semibold text-brandPurple text-center
        cursor-pointer
        mt-8
      "
      @click="skipCamera"
    >
      Skip, I will do it later
    </p> -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Button, SecondaryButton } from "@/UI/Button";
import { SUBMIT_PROFILE_PICTURE } from "@/utils/api/userInfo";
import { KYC_STEPS } from "../../data";
import { HYPERVERGE_TOKEN } from "@/utils/api/verification";
export default {
  components: {
    Button,
    SecondaryButton,
    // "v-easy-camera": () => import("easy-vue-camera"),
  },
  data: () => ({
    loading: false,
    showCamera: false,
    picture: "",
    showPicture: false,
    errorValidatingPicture: false,
    validationMethod: "SELFIE",
    selfieString: "",
  }),
  computed: {
    ...mapState({
      bvn: (state) => state.auth.bvn.number,
      userData: (state) => state?.auth?.user,
    }),
  },
  methods: {
    ...mapActions("dashboard", ["handleKycStep", "getDashboardData"]),
    ...mapActions("media", ["uploadBase"]),
    ...mapActions("userInfo", ["verifyBvn"]),
    ...mapActions('notification', ['showAlert']),
    handleResnap() {
      this.displayCamera();
      this.selfieString = "";
    },
    chosen() {
      this.showCamera = false;
      this.showPicture = true;
      // this.$refs.easyCam.stop();
      // this.$refs.easyCam.close();
      // this.$emit("showButton");
    },
    snap() {
      this.$refs.easyCam.snap();
      this.chosen();
    },
    displayCamera() {
      // this.showPicture = false;
      // this.showCamera = true;

      this.loading = true;
        HYPERVERGE_TOKEN(60000).then(({ data }) => {
          const TOKEN = data.data;
          HyperSnapSDK.init(TOKEN, HyperSnapParams.Region.Africa);
          HyperSnapSDK.startUserSession();
          const hvFaceConfig = new HVFaceConfig();
          hvFaceConfig.setShouldShowInstructionPage(true);
          const callback = (HVError, HVResponse) => {
            if (HVError) {
              const errorMessage = HVError.getErrorMessage();
              this.showAlert({
                display: true,
                type: "error",
                description: errorMessage,
              });
              this.loading = false;
            }
            if (HVResponse) {
              this.loading = true;
              const imageBase64 = HVResponse.getImageBase64();
              this.picture = imageBase64;
              this.uploadProfile();
            }
          };

          HVFaceModule.start(hvFaceConfig, callback);
        });
    },

    closeCamera() {
      this.$refs.easyCam.stop();
      this.showCamera = false;
    },
    uploadProfile() {
      this.loading = true;
      let newPicture = this.picture.slice(22);
      // if it is not a new picture
      if (this.selfieString.length > 0) {
        this.verificationMethod();
      } else {
        this.uploadBase(newPicture)
          .then((res) => {
            let selfieId = res.data.data.id;
            this.selfieString = selfieId;
            this.verificationMethod();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    handleVerifyBvn(selfieId) {
      this.verifyBvn({
        bvn: this.bvn,
        validateWith: this.validationMethod,
        selfieId: selfieId,
      })
        .then(() => {
          this.getDashboardData().then(() => {
            this.loading = false;
            this.handleNextStep();
          });
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            this.$store.dispatch(
              "notification/showAlert",
              {
                display: true,
                type: "error",
                description: err.response.data.message,
              },
              { root: true }
            );
          }
          this.loading = false;
          this.errorValidatingPicture = true;
        });
    },
    handleSubmitSelfie(selfieId) {
      SUBMIT_PROFILE_PICTURE({ selfieId: selfieId })
        .then(() => {
          this.getDashboardData().then(() => {
            this.loading = false;
            this.handleNextStep();
          });
        })
        .catch(() => {
          this.loading = false;
          this.errorValidatingPicture = true;
        });
    },
    verificationMethod() {
      // if BVN has been verified with phone number
      if (this.userData?.identityNumberVerified) {
        this.handleSubmitSelfie(this.selfieString);
      } else {
        this.handleVerifyBvn(this.selfieString);
      }
    },
    skipCamera() {
      this.handleNextStep();
    },
    handleNextStep() {
      if (!this.userData?.phoneVerified) {
        this.handleKycStep(KYC_STEPS.phone);
      } else {
        this.handleKycStep(KYC_STEPS.address);
      }
    },
  },
};
</script>
<style scoped>
.camera-container {
  height: 25rem;
}
img {
  max-height: 20rem;
}
.inner-circle {
  border-radius: 50%;
  background-color: #ccffff;
  padding: 2.5rem;
}
.outer-circle {
  border-radius: 50%;
  background-color: #f5ffff;
  padding: 3rem;
}
</style>
