var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.showCamera
      ? _c("div", [
          !_vm.showPicture
            ? _c(
                "div",
                [
                  _c(
                    "h5",
                    {
                      staticClass:
                        "\n          md:text-2xl\n          text-xl text-center\n          font-bold\n          primary-text\n          leading-10\n        ",
                    },
                    [_vm._v(" Give us camera access ")]
                  ),
                  _c(
                    "p",
                    { staticClass: "text-center text-grey md:text-lg mt-4" },
                    [
                      _vm._v(
                        " You need to submit a selfie to proceed. The photo will only be used to verify your identity. Don’t forget to smile ;) "
                      ),
                    ]
                  ),
                  !_vm.showCamera && !_vm.showPicture
                    ? _c("Button", {
                        staticClass: "mt-8",
                        attrs: {
                          loading: _vm.loading,
                          text: "Launch camera",
                          width: "w-full",
                        },
                        on: { click: _vm.displayCamera },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _c("img", {
                staticClass: "h-full max-w-full rounded-xl mx-auto",
                attrs: { src: _vm.picture, alt: "snapped image" },
              }),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "w-full mt-8" },
      [
        _vm.showCamera
          ? _c("Button", {
              attrs: { text: "Capture", width: "w-full hidden md:block" },
              on: { click: _vm.snap },
            })
          : _vm._e(),
        _vm.showPicture
          ? _c("Button", {
              staticClass: "mx-auto",
              attrs: {
                text: "Submit details",
                width: "w-full",
                loading: _vm.loading,
              },
              on: { click: _vm.uploadProfile },
            })
          : _vm._e(),
        _vm.showPicture
          ? _c("p", { staticClass: "text-black mt-4 text-center" }, [
              _vm._v(" Don't like this shot? "),
              _c(
                "span",
                {
                  staticClass: "cursor-pointer underline",
                  on: { click: _vm.handleResnap },
                },
                [_vm._v(" Let's try again")]
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }